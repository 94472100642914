/*
 * @Author: Ghost 
 * @Email: Ghost@yopmail.com
 * @Date: 2024-02-13 11:30:23 
 * @Last Modified by: Someone
 * Email: anilgodara4239@gmail.com
 * @Last Modified time: 2024-08-22 15:47:31
 * @Description: file:///home/jc-21/Desktop/MyVirtualPal/marketplace-frontend/src/main.js
 */
import { Suspense, lazy, useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { ConnectedRouter } from 'connected-react-router';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import configureStore from './config';
import { createBrowserHistory } from 'history';
import Login from './pages/Login';
import "react-pagination-js/dist/styles.css";
import './scss/main.scss';
import SignUp from './pages/Signup';
import Forgotpassword from './pages/Forgotpassword';
import Resetpassword from './pages/Resetpassword';
import Dashboard from './pages/DashboardPages';
import { Auth } from './methods/auth';
import Profile from './pages/Profile';
import EditProfile from './components/Profile/Edit';
import Home from './pages/Home';
const ChangePassword = lazy(() => import('./components/Profile/ChangePassword'));
import Education from './pages/Education';
export const history = createBrowserHistory();
/************ store configration *********/
import { ToastContainer } from 'react-toastify';
import Employment from './pages/Employment';
import AddEditEmployment from './pages/Employment/AddEditEmployment';
import EmploymentDetail from './pages/Employment/Employmentdetail';
import AddEditEducation from './pages/Education/AddEditEducation';
import EducationDetail from './pages/Education/Educationdetail';
import SkillsType from './pages/SkillsType';
import AddEditSkills from './pages/SkillsType/AddEditSkills';
import SkillsDetail from './pages/SkillsType/skillsdetail';
import Portfolio from './pages/Portfolio';
import Resume from './pages/Resume';
import CompanyDetail from './pages/CompanyDetail';
import NotfoundPage from './pages/Notfoundpage';
import Virtual from './pages/virtual';
import VirtualDetail from './pages/VirtualDetail';
const Quiz = lazy(() => import('./pages/QuizesPages/Quiz'));
const AssesmentListing = lazy(() => import('./pages/QuizesPages/AssesmentListing'));
import Project from './pages/Project';
const Faq = lazy(() => import('./pages/FAQ'));
const Blog = lazy(() => import('./pages/Blogs'));
const BlogDetails = lazy(() => import('./pages/Blogs/Detail'));
import ProjectsList from './pages/VirtualAssistasnceProject/ProjectsList';
import Skill from './pages/Project/skill';
import Budget from './pages/Project/budget';
import Describe from './pages/Project/describe';
import JobDetails from './pages/Project/JobDetails';
import Invite from './pages/InviteFolder/Invite';
import InviteDetails from './pages/InviteFolder/InviteDetails';
import AddEditProject from './pages/Project/AddEditProject';
import ContractsDetails from './pages/Contracts/InviteDetails';
import Contract from './pages/Contracts/Contracts';
import Notifications from './pages/Notifications';
import ShortList from './pages/ShortListPages/ShortList';
import SavedList from './pages/Savedjob/SavedList';
const PerposalSubmit = lazy(() => import('./pages/PerposalSubmit'));
import PerposalList from './pages/PerposalList';
import ProposalDetails from './pages/proposaleDetails/ProposalDetails';
import JobSearch from './pages/JobSearch/JobSearch';
const SavedJob = lazy(() => import('./pages/Savedjob copy/SavedJob'));
import JobApplyDetails from './pages/proposaleDetails/JobApplyDetails';
import CommonDelete from './pages/CommonDeleteActiveModal/CommonDelete';
const Chat = lazy(() => import('./pages/Chat'));
import ReferCode from './pages/ReferCode';
import Refer from './pages/Refer';
const ReferList = lazy(() => import('./pages/ReferList'));
import { ConnectSocket } from './components/global/SocketNetwork/ConnectSocket';
const Hire = lazy(() => import('./pages/Hire/Hire'));
import RecruiterDashboard from './pages/RecruiterDashboard';
const CreateContract = lazy(() => import('./pages/CreateContractPages'));
const OfferDetail = lazy(() => import('./pages/CreateContractPages/OfferDetail'));
import JoinMeeting from './pages/Chat/JoinMeeting';
import ScheduleTable from './pages/ScheduleTables/ScheduleTable';
import TimeOffForm from './pages/TImePolicyTable/TimeP';
import TransactionTable from './pages/CommonTransactionTable/TransactionTable';
import AddEmployerHelper from './pages/EMployerHelper/AddEmployerHelper';
import CompleteChatbox from './pages/Chat/RecruiterChatbox/RecruiterChat';
import environment from './environment';
import StaffAdd from './pages/Profile/StaffAdd';
import StafList from './pages/Profile/StaffListings/StafList';
import VATransactionTable from './pages/CommonTransactionTable/VirtualAssistantTransactionTable';
import TimeScheduleChange from './pages/ChangesAcceptinTIme_Schedule/TimeScheduleChange';
import TermCondition from './pages/Term&Conditions/TermCondition';
import NoticeList from './pages/NoticeDetailer/NoticeList';
import DMContracts from './pages/Contracts/DMContracts';
import Cards from './pages/Cards';
import AddEditCards from './pages/Cards/AddEdit';
import Payment from './pages/Payment';
import BankAccountAddForm from './pages/BankPayment/AddBankAccount';
import BankList from './pages/BankPayment/BankList';
import About from './pages/AboutUS/About';
import Report from './pages/Reports/Report';
import VirtualReport from './pages/Reports/VirtualReport';
import Referalinfromation from './pages/StaticReferalInformationPage/Referalinfromation';
import ContactUs from './pages/ContactusPage/Contact-us';
import { GoogleOAuthProvider } from '@react-oauth/google';
import RecruiterReport from './pages/Reports/RecruiterReport';
import Missions from './pages/Missions/Missions';
import Joinus from './pages/JoinUs/Joinus';
// import Setpassword from './Setpassword/Setpassword'; 
import Setpassword from './pages/Setpassword/Setpassword';
import Virtualdashboard from './pages/VirtualDashboard/Virtualdashboard';
import Submithours from './pages/ApproveVaHours/Submithours';
import Approvehours from './pages/ApproveVaHours/Approvehours';
import TimeClockRequests from './pages/CreateContractPages/TimeClockRequests';
import datepipeModel from './models/datepipemodel';
import ApiClient from './methods/api/apiClient';
import methodModel from './methods/methods';




const { persistor, store } = configureStore(history);

export default () => {
  const user = useSelector(state => state.user)
  useEffect(() => {
    if (user?._id || user?.id) {
      ApiClient.get(`billing/ongoing?userId=${user?._id || user?.id}`).then(res => {
        if (res.success) {
          let FindData = res?.data?.find((item) => !item?.endTime);
          if (FindData) {
            localStorage.setItem('trackingid', FindData?.contractId)
            localStorage.setItem('startTrackingTime', datepipeModel.ReverseTimetoObj((FindData?.startTime).toString()));
            localStorage.setItem('ActualTrackingid', FindData?.id);
            localStorage.setItem('memo', FindData?.memo)
          } else {
            localStorage.removeItem('trackingid');
            localStorage.removeItem('startTrackingTime');
            localStorage.removeItem('ActualTrackingid');
            localStorage.removeItem('memo')
          }
        }
      })
    }
  }, [])
  // useEffect(() => {
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // const handleBeforeUnload = (e) => {
  //   e.preventDefault();
  //   if (localStorage.getItem("ActualTrackingid")) {
  //     ConnectSocket.emit(`update-endTime`, { "id": localStorage.getItem("ActualTrackingid"), "endTime": datepipeModel.TimeConvertToTimezone((new Date()).toString()) })
  //     localStorage.setItem("EventFiredOnClose", true)
  //     localStorage.removeItem('ActualTrackingid');
  //     fetchDataFromApi().then((data) => {
  //       // Process data...
  //       // Remove key after completing the asynchronous operation.
  //       localStorage.removeItem('ActualTrackingid');
  //     });
  //     // This is to show a confirmation prompt in some browsers But It Will Not Work Always Some Browser will display there default Popup Because they have their Policy.
  //     e.returnValue = 'Are you sure? Tracker would be stop';
  //   } else {
  //     console.log('');
  //   }
  // };
  const [OpenChat, setOpenChat] = useState(false);
  return (<>
    {/* <PersistGate loading={'loading ...'} persistor={persistor}> */}
    <Suspense fallback={<div id="loader" className="loaderDiv d-block"></div>}>
      <GoogleOAuthProvider clientId="717040953997-pnqh0vd99t3l0cdt99e9140gfokam96u.apps.googleusercontent.com" scopes={['email', 'profile']} >
        <ConnectedRouter history={history}>
          <CommonDelete show="none" />
          <Router>
            <Switch>
              <Route exact={true} store={store} path="/login" component={Login} />
              <Route exact={true} store={store} path="/signup" component={SignUp} />
              <Route exact={true} store={store} path="/forgotpassword" component={Forgotpassword} />
              <Route exact={true} store={store} path="/resetpassword" component={Resetpassword} />
              <Route exact={true} requireAuth={Auth} store={store} path="/dashboard" component={Dashboard} />
              <Route exact={true} requireAuth={Auth} store={store} path="/profile" component={Profile} />
              <Route exact={true} requireAuth={Auth} store={store} path="/profile/edit" component={EditProfile} />
              <Route exact={true} requireAuth={Auth} store={store} path="/change" component={ChangePassword} />
              <Route exact={true} path="/employment" store={store} requireAuth={Auth} component={Employment} />
              <Route exact={true} path="/employment/add" store={store} requireAuth={Auth} component={AddEditEmployment} />
              <Route exact={true} path="/employment/edit/:id" store={store} requireAuth={Auth} component={AddEditEmployment} />
              <Route exact={true} path="/employmentDetail/:id" store={store} requireAuth={Auth} component={EmploymentDetail} />
              <Route exact={true} path='/join-us' requireAuth={Auth} store={store} component={Joinus} />


              <Route exact={true} path="/education" store={store} requireAuth={Auth} component={Education} />
              <Route exact={true} path="/education/add" store={store} requireAuth={Auth} component={AddEditEducation} />
              <Route exact={true} path="/education/edit/:id" store={store} requireAuth={Auth} component={AddEditEducation} />
              <Route exact={true} path="/educationDetail/:id" store={store} requireAuth={Auth} component={EducationDetail} />


              <Route exact={true} path="/skills" store={store} requireAuth={Auth} component={SkillsType} />
              <Route exact={true} path="/skills/add" store={store} requireAuth={Auth} component={AddEditSkills} />
              <Route exact={true} path="/skills/edit/:id" store={store} requireAuth={Auth} component={AddEditSkills} />
              <Route exact={true} path="/skillsDetail/:id" store={store} requireAuth={Auth} component={SkillsDetail} />

              <Route exact={true} requireAuth={Auth} store={store} path="/portfolio" component={Portfolio} />
              <Route exact={true} requireAuth={Auth} store={store} path="/resume" component={Resume} />
              <Route exact={true} requireAuth={Auth} store={store} path="/detailcompany" component={CompanyDetail} />

              <Route exact={true} requireAuth={Auth} store={store} path="/notfound" component={NotfoundPage} />
              <Route exact={true} requireAuth={Auth} store={store} path="/virtual" component={Virtual} />
              <Route exact={true} requireAuth={Auth} store={store} path="/virtualdetails/:id" component={VirtualDetail} />

              <Route exact={true} requireAuth={Auth} store={store} path="/faq" component={Faq} />
              <Route exact={true} requireAuth={Auth} store={store} path="/quiz" component={Quiz} />
              <Route exact={true} requireAuth={Auth} store={store} path="/assessments" component={AssesmentListing} />
              <Route exact={true} requireAuth={Auth} store={store} path="/project" component={Project} />
              <Route exact={true} requireAuth={Auth} store={store} path="/addeditproject" component={AddEditProject} />
              <Route exact={true} requireAuth={Auth} store={store} path="/addeditproject/:id/:duplicate" component={AddEditProject} />

              <Route exact={true} path="/blogs" store={store} requireAuth={Auth} component={Blog} />
              {/* <Route exact={true} path="/AddEditBlogs" requireAuth={Auth} store={store} component={AddEditBlogs}/>
                            <Route exact={true} path="/AddEditBlogs/:id?" requireAuth={Auth} store={store} component={AddEditBlogs}/> */}
              <Route exact={true} path="/blogsDetails/:id?" requireAuth={Auth} store={store} component={BlogDetails} />

              {/*  Routes for Virtual Assistance Project  */}
              <Route exact={true} path="/projectslist" requireAuth={Auth} store={store} component={ProjectsList} title="Find|Jobs| MyVirtualPal" />
              <Route exact={true} path="/savedlist" requireAuth={Auth} store={store} component={SavedList} />
              <Route exact={true} path="/savedjob" requireAuth={Auth} store={store} component={SavedJob} />
              <Route exact={true} path="/skill" requireAuth={Auth} store={store} component={Skill} />
              <Route exact={true} path="/budget" requireAuth={Auth} store={store} component={Budget} />
              <Route exact={true} path="/describe" requireAuth={Auth} store={store} component={Describe} />
              <Route exact={true} path="/jobdetails" requireAuth={Auth} store={store} component={JobDetails} />
              {/*  Routes for Invites */}
              <Route exact={true} path="/invites" requireAuth={Auth} store={store} component={Invite} />
              <Route exact={true} path="/invite/:id?" requireAuth={Auth} store={store} component={InviteDetails} />

              <Route exact={true} path="/contracts" requireAuth={Auth} store={store} component={Contract} />
              <Route exact={true} path="/contracts/:id?" requireAuth={Auth} store={store} component={ContractsDetails} />
              <Route exact={true} path="/notifications" requireAuth={Auth} store={store} component={Notifications} />

              {/* Routes for Shortlist Data */}
              <Route exact={true} path="/shortlist" requireAuth={Auth} store={store} component={ShortList} />
              <Route exact={true} path="/jobsearch" requireAuth={Auth} store={store} component={JobSearch} />
              <Route exact={true} path="/proposaldetail/:id?" requireAuth={Auth} store={store} component={ProposalDetails} />
              <Route exact={true} path="/perposal/:id?" requireAuth={Auth} store={store} component={PerposalSubmit} />
              <Route exact={true} path="/perposallist" requireAuth={Auth} store={store} component={PerposalList} />
              {/*  Routes for Apply Job Listing */}
              <Route exact={true} path="/details/:id?" requireAuth={Auth} store={store} component={JobApplyDetails} />
              <Route exact={true} path="/chat" requireAuth={Auth} store={store} component={Chat} />
              <Route exact={true} path="/sharereferralcode/:type" requireAuth={Auth} store={store} component={ReferCode} />
              <Route exact={true} path="/refer/:type" requireAuth={Auth} store={store} component={Refer} />
              <Route exact={true} path="/ReferList" requireAuth={Auth} store={store} component={ReferList} />
              <Route exact={true} path="/refer" requireAuth={Auth} store={store} component={Refer} />
              <Route exact={true} path="/hire/:id?" requireAuth={Auth} store={store} component={Hire} />
              <Route exact={true} path="/recruiter/dashboard" requireAuth={Auth} store={store} component={RecruiterDashboard} />
              {/*  URL FOr Create Contract */}
              <Route exact={true} path="/contract" requireAuth={Auth} store={store} component={CreateContract} />
              {/* URL For Offer Details */}
              <Route exact={true} path="/offer/:id?" requireAuth={Auth} store={store} component={OfferDetail} />
              <Route exact={true} path="/home" store={store} component={Home} />

              <Route exact={true} path="/table" store={store} component={ScheduleTable} />
              <Route exact={true} path="/time" store={store} component={TimeOffForm} />
              <Route exact={true} path="/work-diary" store={store} component={TransactionTable} />
              <Route exact={true} path="/trans/virtual" store={store} component={VATransactionTable} />
              <Route exact={true} path='/change/table/:id?' component={TimeScheduleChange} />
              {/*  Route for Create Meeting  */}
              <Route exact={true} path="/create" requireAuth={Auth} store={store} component={JoinMeeting} />
              {/*  Route When Employee want to add their Helper  */}
              <Route exact={true} path="/helper/:roleid?" requireAuth={Auth} store={store} component={AddEmployerHelper} />

              {/*  Route For Staff Add  */}
              <Route exact={true} path="/staff/add" requireAuth={Auth} store={store} component={StaffAdd} />
              <Route exact={true} path="/mystaff" requireAuth={Auth} store={store} component={StafList} />
              <Route exact={true} path="/content/:slug" requireAuth={Auth} store={store} component={TermCondition} />
              <Route exact={true} path="/mission" requireAuth={Auth} store={store} component={Missions} />


              {/* Route for Add Edit Notice */}
              <Route exact={true} path="/notice" requireAuth={Auth} store={store} component={NoticeList} />
              <Route exact={true} path='/dmcontracts' requireAuth={Auth} store={store} component={DMContracts} />
              {/*  For Cards */}
              <Route exact={true} path='/savedcard' requireAuth={Auth} store={store} component={Cards} />
              <Route exact={true} path='/card/add' requireAuth={Auth} store={store} component={AddEditCards} />
              <Route exact={true} path='/card/edit/:id' requireAuth={Auth} store={store} component={AddEditCards} />
              <Route exact={true} path='/payment' requireAuth={Auth} store={store} component={Payment} />
              <Route exact={true} path='/bank/add' requireAuth={Auth} store={store} component={BankAccountAddForm} />
              <Route exact={true} path='/banks' requireAuth={Auth} store={store} component={BankList} />
              <Route exact={true} path='/about' requireAuth={Auth} store={store} component={About} />
              <Route exact={true} path='/report' requireAuth={Auth} store={store} component={Report} />
              <Route exact={true} path='/virtual/report' requireAuth={Auth} store={store} component={VirtualReport} />
              <Route exact={true} path='/referal/info' requireAuth={Auth} store={store} component={Referalinfromation} />
              <Route exact={true} path='/contact-us' requireAuth={Auth} store={store} component={ContactUs} />
              <Route exact={true} path='/recruiter/report' requireAuth={Auth} store={store} component={RecruiterReport} />
              <Route exact={true} path='/set/password' requireAuth={Auth} store={store} component={Setpassword} />
              <Route exact={true} path="/virtual/dashboard" requireAuth={Auth} store={store} component={Virtualdashboard} />
              <Route exact={true} path="/submit/hours/:id" requireAuth={Auth} store={store} component={Submithours} />
              <Route exact={true} path="/approve/hours/:id" requireAuth={Auth} store={store} component={Approvehours} />
              <Route exact={true} path="/timeclock/requests" requireAuth={Auth} store={store} component={TimeClockRequests} />


              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </Router>
        </ConnectedRouter>
      </GoogleOAuthProvider>
    </Suspense>
    {/* </PersistGate>  */}

    {user?.role && user?.role?.id == environment.RecruiterId && OpenChat ? <div className="modal d-block" id="chatmodal" tabindex="-1" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Chat</h5>
            <button type="button" onClick={e => setOpenChat(false)} className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" >&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <CompleteChatbox open={OpenChat} setopen={setOpenChat} />
          </div>
          <div className="modal-footer">

          </div>
        </div>
      </div>
    </div> : null}
    {user?.role && user?.role?.id == environment.RecruiterId && !OpenChat ? <div onClick={e => setOpenChat(true)} className="floating-chat-icon" >
      <span  >🗨️</span>
    </div> : null}
    <div id="loader" className="loaderDiv d-none">
      <div>
        <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
      </div>
    </div>
    <ToastContainer position='top-right' />
  </>
  );
};
