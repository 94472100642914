import React, { useEffect, useState } from "react";
import Html from "./Html";
import "./style.scss";
import axios from 'axios'
import environment from "../../../environment";
import addressModel from "../../../models/address.model";
import ReactGoogleAutocomplete from "react-google-autocomplete";

const GooglePlaceAutoComplete = ({ placeholder, result, id, value }) => {
    const [searchText, setSeatchText] = useState('')
    const search = async (text) => {
        setSeatchText(text)
        result({
            event: 'value',
            value: text
        })
    }

    const placeChange = (place) => {
        setSeatchText(place.formatted_address)
        result({
            event: 'placeChange',
            value: place.formatted_address,
            place
        })
    }





    useEffect(() => {
        setSeatchText(value)
    }, [value])

    return <>
        <div id="initMap" onClick={e => initMap()}></div>
        {/* <Html
            id={id}
            result={result}
            placeholder={placeholder}
            searchText={searchText}
            search={search}
        /> */}
        <ReactGoogleAutocomplete
            apiKey={environment.map_api_key}
            defaultValue={searchText}
            className="form-control"
            onPlaceSelected={(place) => placeChange(place)}
        />
    </>
}
export default GooglePlaceAutoComplete;